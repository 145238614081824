export const filterHtmlElements = (str: string) => {
  const regex = /(<([^>]+)>)/gi;
  return str.replace(regex, "");
};

export const flattenArray = (arr: any) =>
  arr
    .reduce((accumulator: string, currentValue: any) => {
      if (typeof currentValue === "string") {
        return accumulator + " " + filterHtmlElements(currentValue);
      } else if (Array.isArray(currentValue)) {
        return accumulator + " " + flattenArray(currentValue);
      } else if (typeof currentValue === "object") {
        const values = Object.values(currentValue);
        return accumulator + " " + flattenArray(values);
      } else {
        return accumulator;
      }
    }, "")
    .trim();

export const arrayChunk = (a: Array<any>, n: number): Array<Array<any>> =>
  Array.from({ length: Math.ceil(a.length / n) }, (_, i) => a.slice(i * n, i * n + n));

export const getRandomElements = (arr: Array<Object>, num: number): Array<Object> => {
  if (num >= arr.length) {
    return arr; // Return the whole array if num is greater than or equal to array length
  }

  const result: Array<Object> = [];
  const indices = new Set<number>();

  while (indices.size < num) {
    const randomIndex = Math.floor(Math.random() * arr.length);
    indices.add(randomIndex);
  }

  indices.forEach((index: number) => {
    result.push(arr[index]);
  });

  return result;
};
