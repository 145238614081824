import { routes } from "@/configs/navlink.config";
import AnasMadi from "@/assets/img/portfolio/anas.webp";
import MatthewConnelly from "@/assets/img/portfolio/matthew.webp";
import PaulCiullo from "@/assets/img/portfolio/paul.webp";
import SmallAnasMadi from "@/assets/img/portfolio/smallAnas.png";
import SmallMatthewConnelly from "@/assets/img/portfolio/smallMatthew.png";
import AnasHomeBg from "@/assets/img/team/anas-bg.webp";
import MathewHomeBg from "@/assets/img/team/matthew-bg.webp";
import PaulHomeBg from "@/assets/img/team/paul-bg.webp";
import { StaticImageData } from "next/image";

interface ImageType {
  src: StaticImageData;
  title: string;
  alt: string;
}

interface SocialMediaLinks {
  linkedin?: string;
  twitter?: string;
}

export interface TeamMember {
  name: string;
  job: string;
  link: string | null;
  breif: string;
  img: ImageType;
  bgImg: StaticImageData;
  socialMedia: SocialMediaLinks;
  content?: string[];
  smallImg?: ImageType;
  intro?: string;
}

type TeamPortfolioContent = {
  [key: string]: TeamMember;
};

export const teamPortfolioContent: TeamPortfolioContent = {
  matthew: {
    name: "Matthew Connelly",
    job: "CEO",
    link: routes.mathew,
    breif: "Heralding over two decades of experience, Matthew Connelly, our dynamic CEO.",
    intro:
      "Heralding over two decades of experience, Matthew Connelly, our dynamic CEO, is an industry stalwart who's left an indelible mark in the software development and digital marketing landscape.",
    img: {
      src: MatthewConnelly,
      title: `Matthew Connelly: Metachain's Visionary CEO`,
      alt: `CEO Matthew Connelly leads Metachain with expertise in blockchain, digital assets, NFTs, and the metaverse, shaping the future of business`,
    },
    smallImg: {
      src: SmallMatthewConnelly,
      title: `Metachain's CEO: Matthew Connelly`,
      alt: `Matthew Connelly, CEO of Metachain, drives innovation in blockchain, AI, and the Metaverse`,
    },
    bgImg: MathewHomeBg,
    socialMedia: {
      linkedin: "https://www.linkedin.com/in/matt-connelly-26113059",
    },
    content: [
      "Matthew Connelly's early immersion into trailblazing technologies like blockchain showcases his foresight and adaptability.",
      "His unwavering passion for the digital asset domain manifests through his ceaseless efforts to harness blockchain solutions. Akin to a maestro, Matthew orchestrates solutions that address the trust issues businesses grapple with today. He envisages a future where giants leverage blockchain's transformative potential, unlocking unprecedented value and revenue streams.",
      "Matthew Connelly's faith in the boundless possibilities of digital assets, non-fungible tokens (NFTs), and the metaverse fuels his company's vision. Like a skilled cartographer, he charts the roadmap for incorporating these ground-breaking technologies into large-scale enterprises' operations. His ingenious applications for blockchain strive to reconfigure the corporate landscape.",
      "His belief in the metaverse as the Internet's future guides his strategic decisions. Anticipating the metaverse's eventual dominance over the traditional Internet, he uncovers lucrative prospects for those bold enough to venture early. With his futuristic approach, Matthew has strategically positioned the company at the forefront of metaverse technologies.",
      "Beyond his technical acumen, Matthew is celebrated for his extraordinary leadership. His talent for rallying teams propels the company towards its ambitious targets. By nurturing a culture of innovation, he inspires his team to brave the unexplored frontiers of the digital asset domain.",
      "Possessing a visionary mindset and extensive industry experience, Matthew is revered in the blockchain and digital asset communities. His talent for foreseeing trends and identifying untapped opportunities elevates him as an industry thought leader. His perpetual drive to push boundaries and innovate corporate operations in the digital era underscores his status as a change catalyst.",
      "In conclusion, as CEO, Matthew Connelly embodies a rare blend of rich experience and forward-thinking views on blockchain technology, digital assets, NFTs, and the metaverse. His commitment to unlocking new value and revenue opportunities for corporations fuels his leadership and innovation passion. Guiding the integration and adoption of these revolutionary technologies, Matthew is actively sculpting the future of business. His deep-rooted expertise in digital marketing and sales distinguishes him as an original pioneer in the digital landscape.",
    ],
  },
  anas: {
    name: "Anas Madi",
    job: "CTO",
    link: routes.anas,
    breif: "Forward thinking leader of a distinguished team of technical experts.",
    intro:
      "A forward-thinking Chief Technology Officer (CTO), Anas Madi, shines in the global tech stage with an impeccable blend of astute technical prowess and transformative leadership.",
    img: {
      src: AnasMadi,
      title: `Anas Madi Metachain's Innovative CTO`,
      alt: `CTO Anas Madi leads Metachain's tech vision, orchestrating software development, AI, and blockchain, inspiring innovation and growth`,
    },
    smallImg: {
      src: SmallAnasMadi,
      title: `Metachain's CTO: Anas Madi`,
      alt: `Anas Madi, Metachain's CTO, shapes the future of digital technology and software development`,
    },
    bgImg: AnasHomeBg,
    socialMedia: {
      twitter: "https://twitter.com/Anas_ps",
      linkedin: "https://www.linkedin.com/in/anasmmadi",
    },
    content: [
      "At the helm of a top-tier software development company, Anas Madi's acumen extends beyond his CTO role, spearheading a proficient ensemble of over 100 developers, designers, and marketing experts.",
      "His technical symphony orchestrates a delicate balance between front-end and back-end development, a dual mastery that enables him to lead a multitude of diverse projects. The strength of his command has manifested in the successful design and deployment of more than 200 applications spanning various business sectors.",
      "Anas Madi's global footprint extends across the tech realm, demonstrating an uncanny ability to simplify complex processes and craft ingenious solutions. His infectious optimism inspires his team, fostering an environment that encourages innovative thought and propels individuals to exceed their potential.",
      "Beyond corporate success, Anas Madi is committed to paving the path for future software development, shaping the industry's future. He serves as a mentor to over 10,000 academy students, investing his time and expertise in developing the next generation of tech mavens.",
      "His ability to build and nurture enduring relationships stands as a testament to his exceptional communication skills. This knack for understanding and addressing stakeholders' needs ensures consistently high-quality outcomes and the delivery of exceptional products and services.",
      "Anas Madi exhibits remarkable foresight in staying a step ahead in the rapid-paced world of tech. Harnessing data analysis and future predictions, he guides his company's strategic path, contributing to its continued growth and sustainability. His proactive attitude, coupled with his innovative mindset, inspires his team to deliver outstanding outcomes.",
      "His leadership goes beyond strategy and extends into shaping an inclusive and creative workplace culture. His prowess in navigating challenges, adapting to change, and rallying his team fosters an energized work atmosphere that leads to the successful accomplishment of company goals.",
      "A major influencer in the software industry, Anas Madi helps mold its trends and standards, ensuring his company remains at the technological forefront. His dual role as a technical guide and mentor amplifies his value to both his organization as well as the broader tech industry.",
      "In essence, Anas Madi embodies the modern CTO, marked by exceptional technical proficiency, inspiring leadership, and an innovative spirit. His impressive track record, dedication to nurturing emerging talent, and a history of extraordinary results cement his influential role in the software development industry. His adeptness in bridging the gap between business and technology teams stems from his vast experience and understanding",
    ],
  },
  paul: {
    name: "Paul Ciullo",
    job: "CFO",
    link: null,
    breif: "Over a decade of experience with a solid foundation in corporate finance.",
    img: {
      src: PaulCiullo,
      title: `Paul Ciullo: Metachain's CFO`,
      alt: `CFO Paul Ciullo oversees Metachain's financial strategy, ensuring growth and stability`,
    },
    bgImg: PaulHomeBg,
    socialMedia: {
      // twitter: "",
      linkedin: "https://www.linkedin.com/in/paul-ciullo-09974313/",
    },
  },
};
