import main1 from "@/assets/img/articles/1/main.jpg";
import second1 from "@/assets/img/articles/1/second.jpg";
import third1 from "@/assets/img/articles/1/third.jpg";

import main2 from "@/assets/img/articles/2/main.jpg";
import second2 from "@/assets/img/articles/2/second.jpg";

import main3 from "@/assets/img/articles/3/main.jpg";
import second3 from "@/assets/img/articles/3/second.jpg";
import third3 from "@/assets/img/articles/3/third.jpg";

import main4 from "@/assets/img/articles/4/blockchain3.webp";
import second4 from "@/assets/img/articles/4/blockchain2.webp";
import third4 from "@/assets/img/articles/4/blockchain1.webp";

import main5 from "@/assets/img/articles/5/main.webp";
import second5 from "@/assets/img/articles/5/second.webp";
import third5 from "@/assets/img/articles/5/third.webp";

import main6 from "@/assets/img/articles/6/main.webp";
import second6 from "@/assets/img/articles/6/second.webp";
import third6 from "@/assets/img/articles/6/third.webp";

import main7 from "@/assets/img/articles/7/main.webp";
import second7 from "@/assets/img/articles/7/second.webp";
import third7 from "@/assets/img/articles/7/third.webp";

export const firstMedia = [
  {
    src: main1,
    alt: "Metachain Technologies presents the Lands Marketplace, transforming how users buy, sell, and trade virtual land with creativity.",
    title: "Crafting a Thriving NFT Marketplace: A Comprehensive Guide",
  },
  {
    src: second1,
    alt: `Discover how to maximize earnings in NFT marketplaces with Metachain's insights on monetization strategies, fees, and token royalties`,
    title: `NFT Revenue Streams: A Metachain Guide`,
  },
  {
    src: third1,
    alt: `Metachain's Lands Marketplace caters to real estate visionaries, enterprises, and architects, offering vibrant virtual scenes.`,
    title: `Lands Marketplace: Unleash Metaverse Power`,
  },
];

export const secondMedia = [
  {
    src: main2,
    title: `Unlocking NFT Marketplace Profits Guide `,
    alt: `Explore Metachain's comprehensive look at NFT marketplace revenue, from commission-based models to advanced strategies, shaping digital trade`,
  },
  {
    src: second2,
    title: ` Revenue Streams in NFT Marketplaces`,
    alt: `Maximize earnings with expert insights on monetization strategies and token royalties`,
  },
];

export const thirdMedia = [
  {
    src: main3,
    title: `Web2 to Web3: A Metachain Guide`,
    alt: `Learn to migrate from Web2 to Web3 with Metachain. Explore decentralization, blockchain, cryptocurrencies, and the future of the web`,
  },
  {
    src: second3,
    title: `Migrate to Web3: Unlock the Future`,
    alt: `Metachain's comprehensive guide on Web3 migration. Discover the transformative capabilities of decentralization, smart contracts, and more.`,
  },
  {
    src: third3,
    title: `Web3 Transformation, Metachain's Insight`,
    alt: `Shift from Web2 to Web3 with Metachain and Uncover the power of blockchain, decentralized finance, and the programmable economy.`,
  },
];

export const fourthMedia = [
  {
    src: main4,
    title: `Blockchain & AI: Secure Data Sharing Revolution`,
    alt: `Metachain's insights on leveraging blockchain and AI for secure data sharing, enhancing privacy, efficiency, and innovation in various industries.`,
  },
  {
    src: second4,
    title: `secure Data Exchange with Blockchain & AI`,
    alt: `Explore Metachain's guide to AI and blockchain in data security, from anomaly detection to secure multi-party computation and real-time monitoring.`,
  },
  {
    src: third4,
    title: `AI & Blockchain in Data Privacy`,
    alt: `How Metachain integrates AI and blockchain for secure data sharing, fostering innovation in healthcare, finance, and supply chain management`,
  },
];

export const fifthMedia = [
  {
    src: main5,
    title: `Revolutionize Customer Experience with AI`,
    alt: `Metachain's insights on AI-driven customer experience, enabling hyper-personalization, predictive analytics, and intelligent automation.`,
  },
  {
    src: second5,
    title: `A 2023 Outlook of AI in Customer Experience`,
    alt: `Explore AI's transformative role in customer experience with Metachain. Discover chatbots, recommendation systems, and sentiment analysis.`,
  },
  {
    src: third5,
    title: `AI Shaping the Future of Customer Service`,
    alt: `Metachain's guide to AI in customer experience, from 24/7 support to personalized recommendations, enhancing satisfaction and loyalty.`,
  },
];

export const sexthMedia = [
  {
    src: main6,
    title: `Conversational AI: 2024's Transformative Tech`,
    alt: `Dive into the future of conversational AI. Understand trends, challenges, and the role of AI in reshaping human-machine interaction.`,
  },
  {
    src: second6,
    title: `2024 Predictions: Conversational AI's New Era`,
    alt: `Discover the future of conversational AI. Embrace the advancements in voice recognition, NLP, and AI-powered human-like dialogues.`,
  },
  {
    src: third6,
    title: `SpeakGPT, the future of voice chatbots is here`,
    alt: `Explore the 2024 predictions for conversational AI. From voice assistants to NLP, see the future unfold`,
  },
];

export const seventhMedia = [
  {
    src: main7,
    title: `AI: Fueling Business Growth in 2024`,
    alt: `Explore Metachain's insights on AI's transformative impact on businesses, from automation to data-driven decisions, innovation, and efficiency.`,
  },
  {
    src: second7,
    title: `Revolutionize Business with AI: A 2024 Outlook`,
    alt: `Metachain's guide to AI's potential for business growth, including customer experience, operational efficiency, and overcoming challenges.`,
  },
  {
    src: third7,
    title: `AI-Driven Growth: Metachain's Business Vision`,
    alt: `Discover how AI is reshaping businesses with Metachain. Uncover AI's role in automation, innovation, customer service, and the global economy.`,
  },
];
